import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Select, PageHeader } from "antd";
import Services from "./Services";
//import Staff from "./Staff";
import SelectStaff from "./SelectStaff";
import { checkingInWalkInAppointment } from "../../../redux/actions/checking-in/checking-in";
import { getActivePromotions } from "../../../redux/actions/promotion/promotion";
import moment from "moment";
import { Route, Switch, Redirect } from "react-router-dom";
import ClientForm from "./ClientForm";
import Confirmation from "./Confirmation";
import { ClockCircleOutlined } from '@ant-design/icons'
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { nationalFormatPhone } from "../../../utils/FormatPhoneNumber/FormatPhoneNumber";
const { Option } = Select;

class SelectServicesAndStaffs extends Component {
    state = {
        services: [],
        preferedStaff: null,
        staffPickedByClient: false,
        businessClient: this.props.businessClient,
        // modalVisible: true,
        // clientModalVisible: false,
    };

    componentDidMount() {
        if (!this.props.phone || !this.props.salonInfo) {
            const location = this.props.location;
            this.props.history.push(`/${location}/checking-in/`);
        }

        this.props.getActivePromotions(this.props.location);
    }

    handleUpdateClient = (businessClient) => {
        this.setState({ businessClient })
    }

    handleUpdatePreferedStaff = (preferedStaff) => {
        let { staffPickedByClient } = this.state;

        if (preferedStaff !== null) {
            staffPickedByClient = true
        }

        this.setState({ preferedStaff, staffPickedByClient })
    }

    handleSelectService = (service) => {
        let { services } = this.state;

        if (
            services.length > 0 &&
            services.filter((item) => item._id === service._id)
                .length > 0
        ) {
            services = services.filter(
                (item) => item._id !== service._id
            );
        } else {
            services = [...services, service];
        }

        this.setState({ services });
    };

    getAvailableStaffForService = (selectedService) => {
        const { menu, staffs, staffHours } = this.props;
        let formatStaff = [];

        if (menu !== null) {
            formatStaff = staffs.filter((staff) =>
                selectedService.staffs.includes(staff._id)
            )

            //filter staffs by working hours
            const today = moment().day() 
            formatStaff = formatStaff.filter((staff) => {
                const staffHour = staffHours.filter(staffHour => staffHour.staff === staff._id)[0]
                if(!staffHour || !staffHour[today].isOpen){
                    return false
                }
                let shifts = staffHour[today].shifts 
                const foundShift = shifts.filter(shift => shift.open <= moment().format("HH:mm") && shift.close >= moment().format("HH:mm"))
                if(foundShift.length > 0) {
                    return true
                } else {
                    return false
                }
            } )

            // Format array for Select component
            formatStaff = formatStaff.map((staff) => {
                return <Option
                    style={{ lineHeight: "35px" }}
                    value={staff._id}
                    key={staff._id}
                >
                    {staff.name}
                </Option>
            });
        }

        return formatStaff;
    };

    handleStaffChange = (value, staffName, index) => {
        let { services } = this.state;
        services[index]["staff"] = value;
        services[index]["staffName"] = staffName;

        let deepCloneServices = JSON.parse(JSON.stringify(services));

        this.setState({ services: deepCloneServices });
    };

    handleRemoveService = (id) => {
        let { services } = this.state;
        services = services.filter(service =>
            service._id !== id
        )
        this.setState({ services });
    };

    handleStaffChangeDropdown = (value, staffName, index) => {
        let { services } = this.state;
        services[index]["staff"] = value;
        services[index]["staffName"] = staffName;
        services[index]["preferredStaff"] = staffName;

        let deepCloneServices = JSON.parse(JSON.stringify(services));

        this.setState({ services: deepCloneServices, staffPickedByClient: true });
    };

    isClientDetailMissing = () => {
        if (Object.keys(this.props.businessClient).length === 0) {
            return true
        }
        else {
            for (const key in this.props.businessClient) {
                if (this.props.businessClient[key] === "") return true
            }
        }
        return false
    }

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;

        if (!executeRecaptcha) {
            return null;
        }

        return await executeRecaptcha();

    };

    handleSubmit = async () => {
        const { phone } = this.props;
        const { services, businessClient, staffPickedByClient } = this.state;
        const location = this.props.location;

        // reCAPTCHA verification
        const token = await this.handleVerifyRecaptcha();

        if (
            services.filter((service) => !service.staff).length > 0 ||
            phone === null
        ) {
            console.log("Select a staff or phone is null");
        } else {
            let appointment = {
                services: services.map((service) => {
                    let formattedService = {
                        _id: service._id,
                        name: service.name,
                        price: service.price,
                        duration: service.duration,
                        start: moment().format("HH:mm"),
                        end: moment()
                            .add(service.duration, "m")
                            .format("HH:mm"),
                        staff: service.staff,
                        staffName: service.staffName,
                    };
                    if (service.preferredStaff) {
                        formattedService.preferredStaff = service.preferredStaff
                    }
                    return formattedService
                }),
                staffPickedByClient,
                date: moment().format("YYYY-MM-DD"),
                status: "Checked-in",
                type: 2,
                notes: "",
                createdBy: "Client",
            };

            this.props.checkingInWalkInAppointment(
                location,
                { ...businessClient, phone: phone },
                appointment,
                token
            );
        }
    };

    render() {
        const { phone, menu, location, history, salonInfo, isClientUrl } = this.props;
        const { services, preferedStaff, businessClient } = this.state;
        if (!phone || !salonInfo || !menu) {
            const location = this.props.location;
            return <Redirect to={`/${location}/checking-in/`} />
        }
        return (
            <div >
                <PageHeader
                    ghost={false}
                    style={{ position: "sticky", top: "0px", zIndex: "99" }}
                    onBack={() => window.history.back()}
                    title={
                        <div>
                              <span>{nationalFormatPhone(phone, this.props.country)}</span>
                        </div>
                    }
                    subTitle={window.innerWidth > 1000
                        ? <div style={{ marginLeft: "70px" }}>
                            <span style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }}>{salonInfo.name}</span>
                            <span style={{ paddingLeft: "10px" }}>{salonInfo.address}</span>
                        </div>
                        : null
                    }
                    extra={
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            paddingLeft: "5px",
                            fontWeight: "bold",
                            fontSize: "16px"
                        }}>
                            <ClockCircleOutlined />
                            <span style={{ paddingLeft: "5px" }}>{moment().format('h:mm a')}</span>
                        </div>
                    }
                ></PageHeader>


                <Row justify="space-around" >
                    <Switch>
                        <Route path="/:location/checking-in/select/services" exact>
                            <Services
                                location={location}
                                history={history}
                                menu={menu}
                                services={services}
                                handleSelectService={this.handleSelectService}
                                isClientDetailMissing={this.isClientDetailMissing}
                            />
                        </Route>

                        <Route
                            path="/:location/checking-in/select/staff"
                            exact
                        >
                            <SelectStaff
                                services={services}
                                location={location}
                                history={history}
                                preferedStaff={preferedStaff}
                                handleStaffChange={this.handleStaffChange}
                                handleUpdatePreferedStaff={this.handleUpdatePreferedStaff}
                                isClientDetailMissing={this.isClientDetailMissing}

                            />
                        </Route>

                        <Route
                            path="/:location/checking-in/select/client-form"
                            exact
                        >
                            <ClientForm
                                businessClient={businessClient}
                                location={location}
                                history={history}
                                handleUpdateClient={this.handleUpdateClient}
                                isClientUrl={isClientUrl}
                                phone={phone}
                            />
                        </Route>

                        <Route
                            path="/:location/checking-in/select/confirmation"
                            exact
                        >
                            <Confirmation
                                services={services}
                                location={location}
                                handleSubmit={this.handleSubmit}
                                handleRemoveService={this.handleRemoveService}
                                handleStaffChangeDropdown={this.handleStaffChangeDropdown}
                                getAvailableStaffForService={this.getAvailableStaffForService}
                                handleStaffChange={this.handleStaffChange}
                            />
                        </Route>
                    </Switch>
                </Row>

                {/* <Row justify="center" className="select-services-sticky-container">
                    <Col xl={10} lg={18} md={18} sm={24} >
                        <Button
                            // htmlType={step === 2 ? "submit" : "button"}
                            // form={step === 2 ? "client-form" : null}
                            type="primary"
                            size="large"
                            shape="round"
                            style={{ height: "45px", float: "right" }}
                            onClick={() =>
                                this.handleGoNext()
                            }
                            disabled={
                                services.length === 0
                            }
                        >
                            Next <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    salonInfo: state.checkingIn.business,
    message: state.checkingIn.message,
    phone: state.checkingIn.phone,
    businessClient: state.checkingIn.businessClient,
    menu: state.checkingIn.menu,
    staffs: state.checkingIn.staffs,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
});

const mapDispatchToProps = (dispatch) => {
    return {
        checkingInWalkInAppointment: (
            business,
            businessClient,
            appointment,
            token
        ) =>
            dispatch(
                checkingInWalkInAppointment(
                    business,
                    businessClient,
                    appointment,
                    token
                )
            ),

        getActivePromotions: (business) =>
            dispatch(getActivePromotions(business)),
    };
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(SelectServicesAndStaffs));
