import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import { SmileTwoTone, ArrowLeftOutlined } from "@ant-design/icons";
import { resetCheckingInData } from "../../../redux/actions/checking-in/checking-in"



class Thankyou extends Component {

    state = {
        secondCounter: 10
    }


    timer = () => {
        let { secondCounter } = this.state
        if (secondCounter > 0) {
            secondCounter = secondCounter - 1
            this.setState({
                secondCounter
            })
        } else {
            this.goToHomePage()
        }

    }

    componentDidMount() {
        this.id = setInterval(this.timer, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.id)
    }




    goToHomePage = () => {
        const location = this.props.match.params.location;
        this.props.resetCheckingInData()
        // return <Redirect to={`/${location}/checking-in/`}/>
        this.props.handleChangeCountry(this.props.business.country)
        this.props.history.push(`/${location}/checking-in/`);
    };

    render() {
        const { business } = this.props;
        const { secondCounter } = this.state
        return (
            <Row
                justify="center"
                style={{
                    padding: "20px",
                    width: "100%",
                    position: "relative",
                    marginTop: "10%",
                }}
            >
                <Col md={16} lg={14} xl={12} sm={24} style={{ width: "100%" }}>
                    <div className="box box-down green animate__animated animate__fadeInUp">
                        {business && (
                            <h2 style={{ fontWeight: "bold" }}>
                                {business.name}
                            </h2>
                        )}

                        <h2 style={{ fontSize: "40px", textAlign: "center" }} className="animate__animated animate__zoomIn animate__delay-1s">

                            <SmileTwoTone twoToneColor="#52c41a" />  Gracias</h2>
                        <h2>

                            Gracias por visitar nuestro salón. Por favor, tome asiento
                            ¡y le atenderemos pronto!
                        </h2>
                        <Button type="primary" style={{ marginTop: "30px" }} size="large" shape="round" onClick={() => this.goToHomePage()}>
                            <ArrowLeftOutlined /> Volver a la página de inicio (en {secondCounter}s)
                        </Button>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
});

const mapDispatchToProps = (dispatch) => {
    return {
        resetCheckingInData: () => dispatch(resetCheckingInData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
