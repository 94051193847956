import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Divider, PageHeader } from "antd";
import { CheckCircleTwoTone, ArrowLeftOutlined } from "@ant-design/icons";
import { convertMinsToHrsMins } from "../../../utils/util/utility";
import QueueAnim from 'rc-queue-anim';
import moment from "moment"
import { checkingInAppointments } from "../../../redux/actions/checking-in/checking-in"
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import ClientForm from "../SelectService/ClientForm";
import { nationalFormatPhone } from "../../../utils/FormatPhoneNumber/FormatPhoneNumber";

class ConfirmAppointment extends Component {
    state = {
        selectedAppointments: [],
        renderClientForm: false,
    };

    componentDidMount() {
        const { phone, appointments } = this.props
        const location = this.props.match.params.location;
        if (!phone) {
            this.props.history.push(`/${location}/checking-in/`);
        }
        if (appointments.length > 0) {
            const selectedAppointments = this.props.appointments.map(app => app._id)
            this.setState({
                selectedAppointments
            })
        }
    }

    componentDidUpdate() {
        if(this.props.message) {
            const location = this.props.match.params.location;
            this.props.history.push(`/${location}/checking-in/thank-you`);
        }
    }

    formatAppointments = (appointments) => {
        const { selectedAppointments } = this.state;
        let formatAppointments = appointments;

        if (formatAppointments.length > 0) {
            formatAppointments = formatAppointments.map((appointment) => {
                appointment.selected = false;
                if (selectedAppointments.length > 0 && selectedAppointments.includes(appointment._id)) {
                    appointment.selected = true;
                }
                return appointment;
            });
        }

        return formatAppointments;
    };

    handleSelectAppointment = (appointmentId) => {
        let { selectedAppointments } = this.state;

        if (selectedAppointments.length > 0 && selectedAppointments.includes(appointmentId)) {
            selectedAppointments = selectedAppointments.filter(item => item !== appointmentId)
        } else {
            selectedAppointments.push(appointmentId)
        }

        this.setState({ selectedAppointments });
    };

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;

        if (!executeRecaptcha) {
            return null;
        }

        return await executeRecaptcha();

    };

    handleRenderClientForm = async () => {
        const {businessClientFromDB} = this.props 
        const keys = Object.keys(businessClientFromDB)
        if(keys.length === 1 && keys[0] === "_id") {
            await this.handleConfirm(businessClientFromDB)
        } else {
            this.setState({
                renderClientForm: true
            })
        }
     
    }


    handleConfirm = async (businessClient) => {
        // reCAPTCHA verification
        const token = await this.handleVerifyRecaptcha();

        const business = this.props.match.params.location;
        const { selectedAppointments } = this.state
        this.props.checkingInAppointments(business, selectedAppointments, token, businessClient)
    };

    render() {
        const { appointments, phone } = this.props;
        const { selectedAppointments, renderClientForm } = this.state;

        const formatAppointments = this.formatAppointments(appointments);

        return (
            <div>
                <PageHeader
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={nationalFormatPhone(phone, this.props.country)}

                ></PageHeader>

                {!renderClientForm && <Row justify="center" style={{ padding: "20px", width: "100%", position: "relative" }}>
                    <Col md={16} lg={14} xl={12} sm={24} style={{ width: "100%" }}>
                        {/* <Row justify="center">
                        <h2 style={{fontWeight: "bold"}}>{phone} <Button size="large" type="link" onClick={() => this.props.history.goBack()} icon={<EditOutlined />}>Edit</Button></h2>
                    </Row> */}
                        <Row justify="center">
                            <h3>Confirmar <strong className={selectedAppointments.length > 0 ? "text-green" : ""}>{selectedAppointments.length}</strong> {selectedAppointments.length > 1 ? "Appointments" : "Appointment"}</h3>
                        </Row>
                        <QueueAnim type={['right', 'left']} ease={['easeOutQuart', 'easeInOutQuart']} className="queue-simple">

                            {formatAppointments.map(appointment => {
                                return (
                                    <div
                                        key={appointment._id}
                                        className={appointment.selected ? "box box-down green" : "box box-down blue"}
                                        onClick={() => this.handleSelectAppointment(appointment._id)}
                                        style={{ padding: "5px 15px" }}
                                    >
                                        <h2 style={{ margin: "0px" }}>{moment(appointment.services[0].start, "HH:mm").format("h:mmA")} on {moment(appointment.date, "YYYY-MM-DD").format("ddd, DD MMMM")}  <span className="checking-in-float-right">{appointment.selected && <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "25px" }} />}</span></h2>
                                        <Divider style={{ margin: "5px" }} />
                                        {appointment.services.map(service => {
                                            return <div key={service._id}>
                                                <h3> {service.name} <span className="checking-in-float-right">${Number(service.price).toFixed(2)}</span></h3>
                                                <p>con {service.staffName} <span className="checking-in-float-right">{convertMinsToHrsMins(service.duration)}</span></p>
                                            </div>
                                        })}
                                    </div>
                                );
                            })}
                        </QueueAnim>
                        <Row justify="space-around" style={{ width: "100%", position: "sticky", bottom: "0px", marginTop: "30px", backgroundColor: "#F0F2F5", paddingTop: "10px" }}>
                            <Col span={11}>
                                <Button
                                    onClick={() => this.props.history.goBack()}
                                    type="default"
                                    size="large"
                                    shape="round"
                                    style={{ width: "100%" }}
                                >
                                    <ArrowLeftOutlined />Volver
                                </Button>
                            </Col>
                            <Col span={11}>
                                <Button
                                    onClick={() => this.handleRenderClientForm()}
                                    disabled={selectedAppointments.length === 0}
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    style={{ width: "100%" }}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row> }
                {renderClientForm && <ClientForm isClientUrl={this.props.isClientUrl}  handleUpdateClient={this.handleConfirm} isNotRequiredConfirmed={true}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    appointments: state.checkingIn.appointments,
    businessClientFromDB: state.checkingIn.businessClient,
    phone: state.checkingIn.phone,
    message: state.checkingIn.message
});

const mapDispatchToProps = (dispatch) => {
    return {
        checkingInAppointments: (business, appointmentIds, token, businessClient) => dispatch(checkingInAppointments(business, appointmentIds, token, businessClient)),
    };
};


export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(ConfirmAppointment));
