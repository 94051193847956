import React, { Component } from 'react'
import { Result, Button } from "antd"
export default class NotFoundPage extends Component {
  render() {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Lo sentimos, la página que ha visitado no existe.'
        extra={
          <Button type="primary" href="https://www.dashbooking.com" key="goHome">
            Volver a casa
          </Button>
        }
      />
    )
  }
}
