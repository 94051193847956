import {
    GET_CHECKING_IN_SALON_INFO_STARTED,
    GET_CHECKING_IN_SALON_INFO_SUCCESS,
    GET_CHECKING_IN_SALON_INFO_FAILURE,
    GET_PHONE_APPOINTMENTS_STARTED,
    GET_PHONE_APPOINTMENTS_SUCCESS,
    GET_PHONE_APPOINTMENTS_FAILURE,
    CHECKING_IN_APPOINTMENTS_STARTED,
    CHECKING_IN_APPOINTMENTS_SUCCESS,
    CHECKING_IN_APPOINTMENTS_FAILURE,
    CHECKING_IN_WALK_IN_APPOINTMENT_STARTED,
    CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS,
    CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE,
    // CREATE_BUSINESS_CLIENT_STARTED,
    // CREATE_BUSINESS_CLIENT_SUCCESS,
    // CREATE_BUSINESS_CLIENT_FAILURE,
    // UPDATE_BUSINESS_CLIENT_STARTED,
    // UPDATE_BUSINESS_CLIENT_SUCCESS,
    // UPDATE_BUSINESS_CLIENT_FAILURE,
    GET_SERVICES_FOR_WALK_IN_STARTED,
    GET_SERVICES_FOR_WALK_IN_SUCCESS,
    GET_SERVICES_FOR_WALK_IN_FAILURE,
    REMOVE_ERROR_MESSAGE,
    RESET_CHECKING_IN_DATA,
    REDIRECT_TO_NOTFOUND,
} from "../../constants/checking-in/checking-in";
import moment from "moment"


const initialState = {
    appointments: [],
    pendingServices: [],
    allServicesToday: [],
    blockTimes: [],
    businessClient: { name: "Dash Booking" },
    isFetchingAppointment: false,
    business: null,
    staffs: null,
    staffHours: null,
    holidays: [],
    menu: null,
    phone: null,
    loading: false,
    loadingPendingServices: false,
    error: null,
    message: null,
    redirectToNotFound: false,
};

export default function checkingIn(state = initialState, action) {
    switch (action.type) {
        case GET_SERVICES_FOR_WALK_IN_STARTED:
            return {
                ...state,
                loadingPendingServices: true,
                error: null,
                message: null,
            };
        case GET_SERVICES_FOR_WALK_IN_SUCCESS:
            let allSales = action.payload.data.pendingServices
            return {
                ...state,
                loadingPendingServices: false,
                pendingServices: allSales.filter(sale => sale.status === "Pending"),
                allServicesToday: allSales,
                blockTimes: action.payload.data.blockTimes
            };
        case GET_SERVICES_FOR_WALK_IN_FAILURE:
            return {
                ...state,
                loadingPendingServices: false,
                error: action.payload.error,
            };

        case GET_CHECKING_IN_SALON_INFO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_CHECKING_IN_SALON_INFO_SUCCESS:
            const holidays = action.payload.data.holidays
            let staffs = action.payload.data.staffs
            if (holidays && holidays.length > 0) {
                const isInBusinessHoliday = holidays.filter(holiday => holiday.type === "Business" && moment().isBetween(holiday.from, holiday.to, 'day', "[]")).length !== 0
                if (isInBusinessHoliday) {
                    staffs = []
                } else {
                    let staffsOnHoliday = holidays.filter(holiday => holiday.type === "Staff").map(hol => hol.staffs).flat()
                    staffs = staffs.filter(staff => !staffsOnHoliday.includes(staff._id))
                }

            }

            return {
                ...state,
                business: action.payload.data.business,
                businessHour: action.payload.data.businessHour,
                menu: action.payload.data.menu,
                staffs,
                staffHours: action.payload.data.staffHours,
                holidays: action.payload.data.holidays,
                loading: false,
                error: null,
                message: null,
            };
        case GET_CHECKING_IN_SALON_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_PHONE_APPOINTMENTS_STARTED:
            return {
                ...state,
                appointments: [],
                businessClient: null,
                isFetchingAppointment: false,
                phone: null,
                loading: true,
                error: null,
                message: null,
            };
        case GET_PHONE_APPOINTMENTS_SUCCESS:
            // let businessClient = user
            // if(action.payload.data.businessClient) {
            //     businessClient = action.payload.data.businessClient
            // }

            return {
                ...state,
                appointments: action.payload.data.appointments,
                businessClient: action.payload.data.businessClient,
                isFetchingAppointment: true,
                phone: action.phone,
                loading: false,
                error: null,
                message: null,
            };
        case GET_PHONE_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CHECKING_IN_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CHECKING_IN_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Thank you for visiting our business. Please have a seat, we will serve you soon",
            };
        case CHECKING_IN_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Walk-in appointment checked-in successfully. Please have a seat, we will serve you soon.",
            };
        case CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };


        case REMOVE_ERROR_MESSAGE: {
            return {
                ...state,
                loading: false,
                redirectToNotFound: false,
                error: null,
                message: null,
            };
        }
        case REDIRECT_TO_NOTFOUND: {
            return {
                ...state,
                redirectToNotFound: true,
            };
        }
        case RESET_CHECKING_IN_DATA: {
            return {
                ...state,
                appointments: [],
                pendingServices: [],
                businessClient: null,
                isFetchingAppointment: false,
                phone: null,
                loading: false,
                error: null,
                message: null,
                redirectToNotFound: false,
            };
        }
        default:
            return {
                ...state,
            };
    }
}
