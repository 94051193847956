import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Form,
    Input,
    Select,
    Cascader,
    Checkbox,
    Row,
    Col,
    Button,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MonthDate } from "../../common/MonthDate/MonthDate";

class ClientForm extends Component {
    state = {
        disableSubmitBtn: true,
        businessClient: {},
    };

    formRef = React.createRef();

    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    };

    componentDidMount() {
        document.querySelector("main").scrollTo(0, 0);

        const { isClientUrl, phone } = this.props
        if (isClientUrl) {
            const localClientData = JSON.parse(localStorage.getItem("checkingInUser"))

            if (localClientData && localClientData.phone === phone) {
                for (let [key, value] of Object.entries(localClientData)) {
                    if (value) {
                        if (key === "birthday") {
                            value = value.split(" ")
                        }
                        this.formRef.current.setFieldsValue({
                            [key]: value
                        })

                    }
                }
            }
        }

    }

    //check if it is a client url for checking in
    //then save client data in localstorage
    saveClientData = (businessClient) => {
        const { isClientUrl, phone } = this.props;
        let localClientData = JSON.parse(localStorage.getItem("checkingInUser")) || {
            firstName: "",
            lastName: "",
            gender: "",
            birthday: "",
            email: "",
            phone: "",
        }
        if (isClientUrl) {
            for (const [key, value] of Object.entries(businessClient)) {

                if ((!localClientData[key] || localClientData[key] !== value) && key !== "_id") {

                    localClientData[key] = value;
                }
            }
            if (phone) {
                localClientData.phone = phone;
            }
            if (localClientData._id) {
                delete localClientData._id
            }
            localStorage.setItem("checkingInUser", JSON.stringify(localClientData));
        }
    };

    handleSubmitClientInfo = () => {
        //let business = this.props.location

        //businessClient
        let businessClient = {
            ...this.formRef.current.getFieldsValue(),
            _id: this.props.businessClientFromDB._id,
        };
        if (businessClient.birthday && businessClient.birthday.length > 0) {
            businessClient.birthday = businessClient.birthday.join(" ");
        }

        for (let key in businessClient) {
            if (
                !businessClient[key] ||
                businessClient[key].length === 0 ||
                key === "agreement"
            ) {
                delete businessClient[key];
            }
        }
        this.saveClientData(businessClient)


        this.props.handleUpdateClient(businessClient);

        !this.props.isNotRequiredConfirmed && this.props.history.push(
            `/${this.props.location}/checking-in/select/confirmation`
        );
    };

    renderFormItem = (fieldName) => {
        const { businessClientFromDB } = this.props
        if (!this.isEmpty(businessClientFromDB) && businessClientFromDB.firstName !== "" && businessClientFromDB[fieldName] === "") {
            return true
        } else {
            return false
        }
    }

    isReadyToCreate = (values) => {
        return values.agreement && values.firstName ? false : true;
    };

    render() {
        const { businessClient } = this.state;
        const { businessClientFromDB } = this.props
        return (
            <div style={{ width: "100%" }}>
                <Row
                    justify="center"
                    style={{
                        paddingTop: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                        Introduzca sus datos
                    </Col>
                </Row>
                <Row
                    justify="center"
                    style={{
                        padding: "20px 0 20px 0",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <Col
                        xl={10}
                        lg={18}
                        md={18}
                        sm={23}
                        xs={23}
                        style={{ width: "100%" }}
                    >
                        <Form
                            initialValues={{
                                firstName: businessClient.firstName,
                                lastName: businessClient.lastName,
                                email: businessClient.email,
                                gender: businessClient.gender,
                                birthday: businessClient.birthday?.split(" "),
                                // address: businessClient.address,
                            }}
                            style={{
                                padding: "30px 20px 20px 10px",
                                borderRadius: "10px",
                                background: "white",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            }}
                            id="client-form"
                            onFinish={this.handleSubmitClientInfo}
                            autoComplete="off"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 26 }}
                            layout="horizontal"
                            ref={this.formRef}
                            size="large"
                            onValuesChange={(values, allValues) => {
                                this.isEmpty(businessClientFromDB) &&
                                    this.setState({
                                        disableSubmitBtn:
                                            this.isReadyToCreate(allValues),
                                    });
                            }}
                        >
                            {(this.isEmpty(businessClientFromDB) ||
                                businessClientFromDB.firstName ===
                                "") && (
                                    <Form.Item
                                        label="Nombre"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor, introduzca su nombre.",
                                            },
                                        ]}

                                    >
                                        <Input
                                            placeholder="Nombre"
                                            allowClear
                                            style={{ borderRadius: "5px", }}
                                        />
                                    </Form.Item>
                                )}

                            {(this.isEmpty(businessClientFromDB) ||
                                businessClientFromDB.lastName ===
                                "") && (
                                    <Form.Item
                                        label="Apellido"
                                        name="lastName"

                                    >
                                        <Input
                                            placeholder="Apellido (opcional)"
                                            allowClear
                                            style={{ borderRadius: "5px", }}
                                        />
                                    </Form.Item>
                                )}

                            {this.renderFormItem("email") && (
                                <Form.Item
                                    label="Correo electrónico"
                                    name="email"
                                    rules={[{ type: "email", message: "Por favor, introduzca un correo electrónico válido." }]}

                                >
                                    <Input
                                        placeholder="ejemplo@mail.com (opcional)"
                                        allowClear
                                        style={{ borderRadius: "5px", }}
                                    />
                                </Form.Item>
                            )}

                            {this.renderFormItem("gender") && (
                                <Form.Item
                                    label="Género"
                                    name="gender"
                                    size="large"

                                >
                                    <Select
                                        placeholder="Seleccione su sexo (opcional)"
                                        style={{ borderRadius: "5px", }}
                                        allowClear
                                    >
                                        <Select.Option value="Male" className="select-gender-option" >
                                            Hombre
                                        </Select.Option>
                                        <Select.Option value="Female" className="select-gender-option" >
                                            Mujer
                                        </Select.Option>
                                        <Select.Option value="Other" className="select-gender-option" >
                                            Otros
                                        </Select.Option>
                                        <Select.Option value="Prefer not to say" className="select-gender-option" >
                                            Prefiero no decirlo
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            )}

                            {this.renderFormItem("birthday") && (
                                <Form.Item
                                    label="Cumpleaños"
                                    name="birthday"
                                    className="less-margin-bottom"
                                >
                                    <Cascader
                                        placeholder="Seleccione su fecha de nacimiento (opcional)"
                                        popupClassName="birthday-cascader-option" className="birthday-cascader"
                                        options={MonthDate}
                                    />
                                </Form.Item>
                            )}

                            {/* {(businessClientFromDB._id &&
                                businessClientFromDB.address ===
                                    "") && (
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    className="less-margin-bottom"
                                >
                                    <Input
                                        placeholder="Your address (optional)"
                                        allowClear
                                    />
                                </Form.Item>
                            )} */}
                            {this.isEmpty(businessClientFromDB) && (
                                <Form.Item
                                    name="agreement"
                                    wrapperCol={
                                        window.innerWidth > 575 && {
                                            offset: 5,
                                            span: 19,
                                        }
                                    }
                                    valuePropName="checked"
                                    className="less-margin-bottom"
                                >
                                    <Checkbox>
                                        Estoy de acuerdo con el{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://home.dashbooking.com/terms-of-service.html"
                                        >
                                            condiciones y servicio
                                        </a>
                                        {" "} y <a target="_blank"
                                            rel="noreferrer" href="https://home.dashbooking.com/privacy-policy.html">política de privacidad</a>
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </Form>
                    </Col>
                </Row>
                <Row
                    justify="center"
                    className="select-services-sticky-container"
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                        <Button
                            block
                            htmlType="submit"
                            form="client-form"
                            type="primary"
                            size="large"
                            shape="round"
                            style={{ height: "45px" }}
                            disabled={
                                this.isEmpty(businessClientFromDB) &&
                                this.state.disableSubmitBtn
                            }
                        >
                            Siguiente <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    businessClientFromDB: state.checkingIn.businessClient,
    phone: state.checkingIn.phone,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
