import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Select, Empty, Space } from "antd";
import { DeleteOutlined, ArrowRightOutlined, GiftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getServicesForWalkIn } from "../../../redux/actions/checking-in/checking-in";
import Loading from "../../common/Loading/Loading";
import moment from "moment";
// import { NewMessageError } from "../../common/MessageError/NewMessageError";
const priceTypeES = {
    "From": "Desde",
    "Fixed": "Fijo"
}
class Confirmation extends Component {
    state = {
        disableNextButton: false
    }
    getFilteredStaffs = () => {
        const { services, staffs } = this.props;
        let filteredStaffs = staffs.filter((staff) => {
            for (let service of services) {
                if (!service.staffs.includes(staff._id)) return false;
            }
            return true;
        });

        // filteredStaffs = this.filterOutStaffWithHolidays(filteredStaffs)

        return filteredStaffs;
    };

    isStaffWorking = (staff) => {
        const { staffHours } = this.props
        const today = moment().day()
        const staffHour = staffHours.filter(staffHour => staffHour.staff === staff._id)[0]
        if (!staffHour || !staffHour[today].isOpen) {
            return false
        }
        let shifts = staffHour[today].shifts
        const foundShift = shifts.filter(shift => shift.open <= moment().format("HH:mm") && shift.close >= moment().format("HH:mm"))
        if (foundShift.length > 0) {
            return true
        } else {
            return false
        }
    }

    assignClosestStaff = (service) => {
        const { pendingServices, staffs, services, allServicesToday } = this.props;

        let staffList = staffs
            .filter((staff) => {
                if (service.staffs.includes(staff._id)) {
                    return this.isStaffWorking(staff)
                } else {
                    return false
                }
            })
            .sort((staffA, staffB) => {
                let countServiceA = allServicesToday.filter(service => service.staff === staffA._id).length
                let countServiceB = allServicesToday.filter(service => service.staff === staffB._id).length
                return countServiceA - countServiceB
            });


        // staffList = this.filterOutStaffWithHolidays(staffList);

        let closestStaff = staffList[0];

        let shortestDiff = 500;
        if (!closestStaff) {
            this.setState({
                disableNextButton: true,
            })
            this.props.handleStaffChangeDropdown(
                null,
                null,
                services.indexOf(service)
            );
        } else {
            if (!this.props.business.assignStaffByTurn) {
                for (let staff of staffList) {
                    let totalDuration = 0;
                    let blockTimes = pendingServices.filter(
                        (blockTime) => blockTime.staff === staff._id
                    );
                    if (blockTimes.length === 0) {
                        closestStaff = staff;
                        break;
                    } else {
                        for (let blockTime of blockTimes) {
                            totalDuration += blockTime.duration;
                        }
                        let availableTime = moment(blockTimes[0].start, "h:mm").add(
                            totalDuration,
                            "minutes"
                        );
                        if (availableTime.diff(moment(), "minutes") < shortestDiff) {
                            shortestDiff = moment().diff(availableTime, "minutes");
                            closestStaff = staff;
                        }
                    }
                }
            }
            this.props.handleStaffChange(
                closestStaff._id,
                closestStaff.name,
                services.indexOf(service)
            );
        }
    };

    filterOutStaffWithHolidays = (staffList) => {
        const { holidays } = this.props;
        let filteredStaffs = [];

        if (staffList.length > 0) {
            // Get all staff holidays for today
            let holidaysOnCurrentDay = holidays.filter(holiday => {
                if (holiday.type === "Staff") {
                    if (moment().isBetween(holiday.from, holiday.to, 'day', "[]")) {
                        return true
                    }
                }
                return false
            })

            // Remove available staff that have a holiday today
            if (holidaysOnCurrentDay.length > 0) {
                holidaysOnCurrentDay.forEach(holiday => {
                    holiday.staffs.forEach(holidayStaff => {
                        filteredStaffs = staffList.filter(staffEach => staffEach._id !== holidayStaff)
                    })
                })
            }
        }

        return filteredStaffs;
    };

    isDayOnPromo = (day) => {
        const { promotion } = this.props
        if ((!promotion.from && !promotion.to && promotion.repeat.includes(day.day()))
            || (day.isBetween(promotion.from, promotion.to, 'day', '[]') && promotion.repeat.includes(day.day()))
        ) {
            return true
        }
        return false
    }

    getPromoItemName = () => {
        const { promotion, services } = this.props
        let itemsName = services
            .filter(service => promotion.selectedItems.includes(service._id))
            .map(service => service.name)
        return itemsName
    }

    totalPrice = () => {
        const { services } = this.props

        let total = 0
        for (let service of services) {
            total += service.price
        }
        return total
    }

    componentDidMount() {
        if (this.getFilteredStaffs().length === 0) {
            this.props.getServicesForWalkIn(this.props.location);
        }
    }
    componentDidUpdate(prevProps) {
        const { services } = this.props;
        const { pendingServices } = this.props;

        if (prevProps.pendingServices !== pendingServices) {
            if (this.getFilteredStaffs().length === 0) {
                for (let service of services) {
                    this.assignClosestStaff(service);
                    //this.forceUpdate()
                }
            }
        }
    }

    render() {
        let { services, loading, promotion } = this.props;

        const isValidForPromotion =
            promotion
            && this.isDayOnPromo(moment())
            && this.totalPrice() >= promotion.minimum
            && (promotion.promoType === "onCart" || (promotion.promoType === "onItems" && this.getPromoItemName().length > 0))

        if (loading) {
            return <Loading />;
        }
        const { disableNextButton } = this.state
        return (
            <div style={{ width: "100%" }}>
                <Row
                    justify="center"
                    style={{
                        paddingTop: "20px",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                        Confirme su cita
                    </Col>
                </Row>
                <Row
                    justify="center"
                    style={{
                        paddingBottom: "20px",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <Col
                        xl={10}
                        lg={18}
                        md={18}
                        sm={23}
                        xs={23}
                        style={{ width: "100%" }}
                    >
                        {services.length > 0 ? (
                            <Row>
                                <Col style={{ width: "100%" }}>
                                    {services.map((service, index) => {

                                        return (
                                            <Row
                                                key={service._id + index}
                                                className={
                                                    service.staff
                                                        ? "box box-down"
                                                        : "box box-down red"
                                                }
                                                style={{ padding: "20px" }}
                                            >
                                                <Col span={20}>
                                                    <h3
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {service.name}
                                                    </h3>
                                                    <p>
                                                        <span>
                                                            {priceTypeES[service.priceType]}
                                                        </span>{" "}
                                                        €{service.price}
                                                    </p>
                                                </Col>
                                                <Col span={4}>
                                                    <Button
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        type="link"
                                                        danger
                                                        onClick={() =>
                                                            this.props.handleRemoveService(
                                                                service._id
                                                            )
                                                        }
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Col>
                                                <Col span={24}>
                                                    <Select
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className={
                                                            "checking-in-select-staff-for-service"
                                                        }
                                                        size="large"
                                                        placeholder="Select Staff"
                                                        value={service.staff}
                                                        notFoundContent={"There is no staff available for this service. Please talk to the front desk. Thank you!"}
                                                        onChange={(
                                                            value,
                                                            e
                                                        ) => {
                                                            this.props.handleStaffChangeDropdown(
                                                                value,
                                                                e.children,
                                                                index
                                                            );
                                                            //this.forceUpdate()
                                                        }}
                                                    >
                                                        {this.props.getAvailableStaffForService(
                                                            service
                                                        )}
                                                    </Select>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                        ) : (
                            <Empty description="No se ha eliminado el servicio" />
                        )}
                    </Col>
                </Row>
                {
                    isValidForPromotion &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < Space direction="vertical" size="small" style={{ width: "100%", background: "#00c2cb", color: "white" }} className="box">
                                <div style={{ fontWeight: "bold", fontSize: "16px" }}>{promotion.name}</div>
                                <div>
                                    <span >Descuento: </span>
                                    <span>
                                        {promotion.discountType === "amount" && "$"}
                                        {promotion.amount}
                                        {promotion.discountType === "percentage" && "%"}
                                    </span>
                                </div>
                                <div>
                                    Tipo de descuento: {promotion.promoType === "onCart" ? "En el carro" : "En los artículos"}
                                </div>
                                {promotion.promoType === "onItems" && <div>
                                    Artículos con descuento:
                                    <ul>
                                        {this.getPromoItemName().map(item =>
                                            <li key={item}>
                                                {item}
                                            </li>
                                        )}
                                    </ul>
                                </div>}
                            </Space>
                        </Col>
                    </Row>
                }
                {
                    promotion && this.totalPrice() < promotion.minimum &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < div style={{ width: "100%", background: "#f7b31c", padding: "10px 20px" }} >
                                <ExclamationCircleOutlined /> Necesita al menos €{promotion.minimum} para aplicar el descuento de la promoción
                            </div>
                        </Col>
                    </Row>
                }
                {
                    promotion.promoType === "onItems" && this.getPromoItemName().length === 0 &&
                    <Row
                        justify="center"
                        style={{
                            paddingBottom: "20px",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Col
                            xl={10}
                            lg={18}
                            md={18}
                            sm={23}
                            xs={23}
                            style={{ width: "100%" }}
                        >
                            < div style={{ width: "100%", background: "#f7b31c", padding: "10px 20px" }} >
                                <ExclamationCircleOutlined /> Necesita al menos 1 artículo con el icono {<GiftOutlined />} para aplicar el descuento de la promoción.
                            </div>
                        </Col>
                    </Row>
                }
                <Row justify="center">
                    <small style={{ color: "grey", marginTop: "20px" }}>
                        Este sitio está protegido por reCAPTCHA y el sistema Google{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://policies.google.com/privacy"
                        >
                            Política de privacidad
                        </a>{" "}
                        y{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://policies.google.com/terms"
                        >
                            Condiciones de servicio
                        </a>{" "}
                        aplicar.
                    </small>
                </Row>
                <Row
                    justify="center"
                    className="select-services-sticky-container"
                >
                    <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                        <Button
                            block
                            type="primary"
                            size="large"
                            shape="round"
                            style={{ height: "45px" }}
                            onClick={() => {
                                this.props.handleSubmit();
                            }}
                            disabled={services.length === 0 || disableNextButton}
                        >
                            Confirmar <ArrowRightOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.checkingIn.business,
    allServicesToday: state.checkingIn.allServicesToday,
    staffs: state.checkingIn.staffs,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
    pendingServices: state.checkingIn.pendingServices,
    loading: state.checkingIn.loadingPendingServices,
    promotion: state.promotion.activePromotion,

});

const mapDispatchToProps = (dispatch) => {
    return {
        getServicesForWalkIn: (business) =>
            dispatch(getServicesForWalkIn(business)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
