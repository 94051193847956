import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input } from "antd";
import { ArrowRightOutlined, GiftOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
const priceTypeES = {
    "From": "Desde",
    "Fixed": "Fijo"
}
export class Services extends Component {
    state = {
        search: "",
    };

    handleGoNext = () => {
        let { services } = this.props;
        let staffIds = services[0].staffs
        services.forEach(service => {
            staffIds = staffIds.filter(staff => service.staffs.includes(staff))
        })
        const location = this.props.location;

        if (staffIds.length > 0) {
            this.props.history.push(`/${location}/checking-in/select/staff`);
        }

        else if (this.props.isClientDetailMissing()) {
            this.props.history.push(`/${location}/checking-in/select/client-form`);
        }
        else {
            this.props.history.push(`/${location}/checking-in/select/confirmation`);
        }
    }

    searchOnChange = (search) => {
        this.setState({ search })
    };

    servicesWithSearch = (menu, search) => {
        let servicesWithSearch = menu.map((category) => {
            const newServices = [...category.services]
            const newCategory = { ...category, newServices }
            return newCategory
        });

        if (search !== "") {
            servicesWithSearch = servicesWithSearch.filter((category) => {
                category.services = category.services.filter(service => {
                    return service.name.toLowerCase().includes(search.toLowerCase())
                })
                return !!category
            })
        }

        return servicesWithSearch;
    };

    isServiceInPromotion = (item) => {
        return this.props.promotion.selectedItems.includes(item)
    }

    render() {
        const { menu, services, promotion } = this.props;
        const { search } = this.state;

        const servicesWithSearch = this.servicesWithSearch(menu, search);

        return (<div style={{ width: "100%" }}>
            <Row justify="center" style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                    Seleccione sus servicios
                </Col>
            </Row>
            <Row justify="center" style={{ padding: "20px 0 20px 0" }} >

                <Col xl={10} lg={18} md={18} sm={23} xs={23} className="service-container" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                    <Input
                        placeholder="Buscar servicios"
                        allowClear
                        onChange={(e) => this.searchOnChange(e.target.value)}
                        size="large"
                        suffix={<SearchOutlined style={{ color: "lightgrey" }} />}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    {servicesWithSearch !== null &&
                        servicesWithSearch.map((category) => {
                            return (
                                category.services.length > 0 && (
                                    <div
                                        key={category._id}
                                        style={{ margin: "0 0 15px" }}
                                    >
                                        <h2
                                            style={{
                                                margin: "5px 0",
                                                borderLeft: `3px solid ${category.color}`,
                                                borderRadius: "3px",
                                                paddingLeft: "10px",
                                            }}
                                        >
                                            {category.name}
                                        </h2>
                                        <Row>
                                            {category.services.map(
                                                (service) => {
                                                    return (
                                                        <div
                                                            key={
                                                                service._id
                                                            }
                                                            onClick={() =>
                                                                this.props.handleSelectService(
                                                                    service
                                                                )
                                                            }
                                                            className={
                                                                "modal-service " +
                                                                (services.filter(
                                                                    (
                                                                        item
                                                                    ) =>
                                                                        item._id ===
                                                                        service._id
                                                                ).length >
                                                                    0 &&
                                                                    "modal-service-selected")
                                                            }
                                                        >
                                                            <h4
                                                                style={{
                                                                    margin: "0",
                                                                }}
                                                            >
                                                                <strong style={{ paddingRight: "10px" }}>
                                                                    {service.name} {
                                                                        promotion && promotion.promoType === "onItems" && this.isServiceInPromotion(service._id)
                                                                            ? <GiftOutlined style={{ color: "#3190ff" }} />
                                                                            : null
                                                                    }
                                                                </strong>
                                                                <div
                                                                    style={{
                                                                        float: window.innerWidth > 480 && "right",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: "#8c8c8c",
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            priceTypeES[service.priceType]
                                                                        }
                                                                    </span>{" "}
                                                                    €
                                                                    {
                                                                        service.price
                                                                    }
                                                                </div>
                                                            </h4>
                                                            {service.description >
                                                                "" && (
                                                                    <span
                                                                        style={{
                                                                            color: "grey",
                                                                        }}
                                                                    >
                                                                        {
                                                                            service.description
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </div>
                                )
                            );
                        })}

                </Col>
            </Row>
            <Row justify="center" className="select-services-sticky-container">
                <Col xl={10} lg={18} md={18} sm={23} xs={23} >
                    <Button
                        block
                        type="primary"
                        size="large"
                        shape="round"
                        style={{ height: "45px" }}
                        onClick={() =>
                            this.handleGoNext()
                        }
                        disabled={
                            services.length === 0
                        }
                    >
                        Siguiente <ArrowRightOutlined />
                    </Button>
                </Col>
            </Row>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    promotion: state.promotion.activePromotion,

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
