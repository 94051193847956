// export const normalizePhone = (value, previousValue) => {
//     if (!value) return value;
//     const currentValue = value.replace(/[^\d]/g, "");
//     const cvLength = currentValue.length;

//     if (cvLength < 4) return currentValue;
//     if (cvLength < 7)
//         return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`;
//     return `(${currentValue.slice(0, 3)})-${currentValue.slice(
//         3,
//         6
//     )}-${currentValue.slice(6, 10)}`;
// };

// export const validatePhone = (value) => {
//     let error = "";
//     const phoneRegex =
//         /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
//     if (!value) error = "Required!";
//     else if (value.length !== 14 || !phoneRegex.test(value))
//         error = "Invalid phone format. ex: (555)-555-5555";
//     return error;
// };

export function validatePassword(value) {
    const passwordRegex =  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (!passwordRegex.test(value)) {
        return false;
    }
    return true;
}

const allReplace = (str, chars) => {
    var retStr = String(str);
    for (var x in chars) {
        retStr = retStr.replace(new RegExp(x, 'g'), chars[x]);
    }
    return retStr;
};

export const decodeHtmlEntity = (str) => {
    var chars = { '&amp;': '&', '&lt;': '<', '&gt;': '>', '&quot;': '"', '&#x27;': "'", '&#x2F;': "/", " &#x5C;": "\\" };
    return allReplace(str, chars)
    // return String(str).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#x27;/g, "'").replace(/&#x2F;/g, "/");
}





export const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m =  mins % 60 > 0 ?   mins % 60 + "min"  : ""
    return h > 0 ? `${h}h ${m}` : `${m}`
}

export const capitalizeFirstLetter = (string) => {
    const arr = string.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}