import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, List, Avatar, Button } from 'antd'
import { RightOutlined, UserOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getServicesForWalkIn } from "../../../redux/actions/checking-in/checking-in"
import moment from 'moment'
import Loading from '../../common/Loading/Loading'

class SelectStaff extends Component {
    state = {
        disableNextButton: false,
    }

    componentDidMount() {
        this.props.getServicesForWalkIn(this.props.location)
    }

    componentDidUpdate(prevProps) {


        const { pendingServices } = this.props

        if (prevProps.pendingServices !== pendingServices) {
            this.chooseStaff(null)
        }


    }

    handleGoNext = () => {
        const location = this.props.location;


        if (this.props.isClientDetailMissing()) {

            this.props.history.push(`/${location}/checking-in/select/client-form`);
        }
        else {
            this.props.history.push(`/${location}/checking-in/select/confirmation`);
        }
    }

    getFilteredStaffs = () => {
        const { staffs, staffHours, services } = this.props
        let { allServicesToday } = this.props
        let filteredStaffs = staffs
        filteredStaffs = filteredStaffs.filter(staff => {
            for (let service of services) {
                if (!service.staffs.includes(staff._id)) return false
            }
            return true
        })

        // Filter out staff that are not available through staff hours
        const today = moment().day()
        filteredStaffs = filteredStaffs
            .filter((staff) => {
                const staffHour = staffHours.filter(staffHour => staffHour.staff === staff._id)[0]
                if (!staffHour || !staffHour[today].isOpen) {
                    return false
                }
                let shifts = staffHour[today].shifts
                const foundShift = shifts.filter(shift => shift.open <= moment().format("HH:mm") && shift.close >= moment().format("HH:mm"))
                if (foundShift.length > 0) {
                    return true
                } else {
                    return false
                }
            })
            .sort((staffA, staffB) => {
                let countServiceA = allServicesToday.filter(service => service.staff === staffA._id).length
                let countServiceB = allServicesToday.filter(service => service.staff === staffB._id).length
                return countServiceA - countServiceB
            })

        return filteredStaffs
    }

    chooseStaff = (preferedStaff) => {
        const { services } = this.props
        this.props.handleUpdatePreferedStaff(preferedStaff)
        for (let i = 0; i < services.length; i++) {
            if (preferedStaff) {
                this.props.handleStaffChange(preferedStaff._id, preferedStaff.name, i)
            }
            else {
                let closestStaff = this.getClosestStaff()

                if (closestStaff) {
                    this.props.allServicesToday.push({ staff: closestStaff._id, status: "Pending" })

                    this.props.handleStaffChange(closestStaff._id, closestStaff.name, i)
                }
                else {
                    // NewMessageError(null, "There is no staff available for the selected services. Please talk to the front desk. Thank you!")
                    this.setState({
                        disableNextButton: true
                    })
                }

            }
        }
    }

    getClosestStaff = () => {
        let availableStaffs = this.getFilteredStaffs()

        if (availableStaffs.length > 0) {
            let closestStaff = availableStaffs[0]

            // let shortestDiff = 500
            if (!this.props.business.assignStaffByTurn) {
                let smallestPendingServices = 100
                for (let staff of availableStaffs) {
                    // let totalDuration = 0
                    let servicesBlockTimes = this.props.pendingServices.filter(blockTime => blockTime.staff === staff._id)

                    if (servicesBlockTimes.length === 0) {
                        closestStaff = staff
                        break
                    }
                    else {
                        if (smallestPendingServices > servicesBlockTimes.length) {
                            smallestPendingServices = servicesBlockTimes.length
                            closestStaff = staff
                        }

                        // for (let blockTime of servicesBlockTimes) {
                        //     totalDuration += blockTime.duration
                        // }
                        // let availableTime = moment(servicesBlockTimes[0].start, 'HH:mm').add(totalDuration, "minutes")
                        // if (availableTime.diff(moment(), 'minutes') < shortestDiff) {
                        //     shortestDiff = moment().diff(availableTime, 'minutes')
                        //     closestStaff = staff
                        // }
                    }
                }
            }

            return closestStaff
        }
    }

    getAvaiableTime = (staffId) => {
        let servicesBlockTimes = this.props.pendingServices.filter(blockTime => blockTime.staff === staffId)
        let staffBlockTimes = this.props.blockTimes.filter(blockTime => blockTime.staff === staffId)
        const pendingServices = servicesBlockTimes.length
        return <div>
            {
                pendingServices > 0
                    ? <span style={{ color: pendingServices === 1 ? "orange" : "black" }}><strong>{pendingServices}</strong> servicios pendientes</span>
                    : staffBlockTimes.length > 0
                        && moment().isBetween(moment(staffBlockTimes[0].start, "HH:mm"), moment(staffBlockTimes[0].end, "HH:mm"))
                        ? null
                        : <span style={{ color: this.props.preferedStaff?._id !== staffId && "green" }}>Disponible ahora</span>

            }
            {
                staffBlockTimes.length > 0 && staffBlockTimes.map((blockTime, index) => (
                    <span key={index} style={{ display: "block", color: "gray" }}>
                        No disponible de {blockTime.start} a {blockTime.end}
                    </span>
                ))
            }
        </div>

    }

    render() {
        const { loading } = this.props;
        const { disableNextButton } = this.state;

        if (loading) {
            return <Loading />
        }


        const filterStaffs = this.getFilteredStaffs()

        return (<div style={{ width: "100%" }}>
            <Row justify="center" style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold" }}>
                <Col xl={10} lg={18} md={18} sm={23} xs={23}>
                    Seleccione su personal
                </Col>
            </Row>
            <Row
                justify="center"
                style={{ padding: "20px 0 20px 0", width: "100%", position: "relative" }}
            >
                <Col xl={10} lg={18} md={18} sm={23} xs={23} style={{ width: "100%" }}>
                    <List
                        className="booking-staffList"
                        style={{ borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", background: "white", overflow: "hidden" }}
                        dataSource={filterStaffs}
                        locale={{ emptyText: "No hay personal disponible para los servicios seleccionados. Por favor, hable con la recepción. Gracias." }}
                        header={
                            filterStaffs.length > 0 && <List.Item
                                onClick={() => this.chooseStaff(null)}
                                className="booking-list-item"
                                style={{
                                    background: this.props.preferedStaff === null && "#00c2cb",
                                    color: this.props.preferedStaff === null ? "white" : "#272727",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        style={{ marginRight: "20px", background: "#e5f1ff", color: "#2f7fff" }}
                                        size={56}
                                        icon={<UserOutlined />}
                                    />
                                    <div style={{ display: "inline-block" }}>
                                        <div>Ninguna preferencia</div>
                                        <div style={{ fontWeight: "normal", fontSize: "14px" }}>Máxima disponibilidad</div>
                                    </div>
                                </div>
                                <RightOutlined style={{ float: "right", display: window.innerWidth < 480 ? "none" : null }} />
                            </List.Item>
                        }
                        renderItem={staff => (
                            <List.Item
                                onClick={() => this.chooseStaff(staff)}
                                className="booking-list-item"
                                style={{
                                    background: this.props.preferedStaff === staff && "#00c2cb",
                                    color: this.props.preferedStaff === staff ? "white" : "#272727",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar
                                        style={{ marginRight: "20px", background: "#e5f1ff", color: "#2f7fff" }}
                                        size={56} >
                                        {staff.name.charAt(0)}
                                    </Avatar>
                                    <div style={{ display: "inline-block" }}>
                                        <div>{staff.name}</div>
                                        <div style={{ fontWeight: "normal", fontSize: "14px" }}>{this.getAvaiableTime(staff._id)}</div>
                                    </div>
                                </div>
                                <RightOutlined style={{ float: "right", display: window.innerWidth < 480 ? "none" : null }} />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row justify="center" className="select-services-sticky-container">
                <Col xl={10} lg={18} md={18} sm={23} xs={23} >
                    <Button
                        block
                        type="primary"
                        size="large"
                        shape="round"
                        style={{ height: "45px" }}
                        onClick={() =>
                            this.handleGoNext()
                        }
                        disabled={disableNextButton}
                    >
                        Siguiente <ArrowRightOutlined />
                    </Button>
                </Col>
            </Row>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    staffs: state.checkingIn.staffs,
    business: state.checkingIn.business,
    staffHours: state.checkingIn.staffHours,
    holidays: state.checkingIn.holidays,
    pendingServices: state.checkingIn.pendingServices,
    blockTimes: state.checkingIn.blockTimes,
    allServicesToday: state.checkingIn.allServicesToday,
    loading: state.checkingIn.loadingPendingServices
})

const mapDispatchToProps = (dispatch) => {
    return {
        getServicesForWalkIn: (business) => dispatch(getServicesForWalkIn(business)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectStaff)
