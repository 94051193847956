import axios from "axios";
// import Cookies from "js-cookie"
//let authToken = "";

// const user = Cookies.getJSON("user") || null

// if (user) {
//     authToken = user.token
// }


let baseURL = "https://checkin-api.dashbooking.com"
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  baseURL = null
}

export default axios.create({
    withCredentials : true,
    baseURL
    // baseURL: "http://localhost:3007"
    // headers: { common: { Authorization: `Bearer ${authToken}` } }
});
