import {
    GET_CHECKING_IN_SALON_INFO_STARTED,
    GET_CHECKING_IN_SALON_INFO_SUCCESS,
    GET_CHECKING_IN_SALON_INFO_FAILURE,
    GET_PHONE_APPOINTMENTS_STARTED,
    GET_PHONE_APPOINTMENTS_SUCCESS,
    GET_PHONE_APPOINTMENTS_FAILURE,
    CHECKING_IN_APPOINTMENTS_STARTED,
    CHECKING_IN_APPOINTMENTS_SUCCESS,
    CHECKING_IN_APPOINTMENTS_FAILURE,
    CHECKING_IN_WALK_IN_APPOINTMENT_STARTED,
    CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS,
    CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE,
    // CREATE_BUSINESS_CLIENT_STARTED,
    // CREATE_BUSINESS_CLIENT_SUCCESS,
    // CREATE_BUSINESS_CLIENT_FAILURE,
    // UPDATE_BUSINESS_CLIENT_STARTED,
    // UPDATE_BUSINESS_CLIENT_SUCCESS,
    // UPDATE_BUSINESS_CLIENT_FAILURE,
    GET_SERVICES_FOR_WALK_IN_STARTED,
    GET_SERVICES_FOR_WALK_IN_SUCCESS,
    GET_SERVICES_FOR_WALK_IN_FAILURE,
    REMOVE_ERROR_MESSAGE,
    RESET_CHECKING_IN_DATA,
    REDIRECT_TO_NOTFOUND,
} from "../../constants/checking-in/checking-in";

import API from "../../../utils/API/API";


export const getServicesForWalkIn = (business) => {
    return (dispatch) => {
        dispatch(getServicesForWalkInStarted());
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0);
        API.post(`/api/checking-in/get-pending-services`, {
            business,
            date: currentDate,
        })
            .then((res) => {
                dispatch(getServicesForWalkInSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getServicesForWalkInFailure);
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000);
            });
    };
};

const getServicesForWalkInStarted = () => ({
    type: GET_SERVICES_FOR_WALK_IN_STARTED,
});

const getServicesForWalkInSuccess = (data) => ({
    type: GET_SERVICES_FOR_WALK_IN_SUCCESS,
    payload: {
        data,
    },
});

const getServicesForWalkInFailure = (error) => ({
    type: GET_SERVICES_FOR_WALK_IN_FAILURE,
    payload: { error },
});

export const resetCheckingInData = () => {
    return (dispatch) =>
        dispatch({
            type: RESET_CHECKING_IN_DATA,
        });
};

export const getCheckingInSalonInfo = (url) => {
    return (dispatch) => {
        dispatch(getSalonCheckingInSalonInfoStarted());
        API.get(`/api/business-info-checking-in?url=${url}`)
            .then((res) => {
                dispatch(getSalonCheckingInSalonInfoSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getSalonCheckingInSalonInfoFailure);
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 500);
            });
    };
};

const getSalonCheckingInSalonInfoStarted = () => ({
    type: GET_CHECKING_IN_SALON_INFO_STARTED,
});

const getSalonCheckingInSalonInfoSuccess = (data) => ({
    type: GET_CHECKING_IN_SALON_INFO_SUCCESS,
    payload: {
        data,
    },
});

const getSalonCheckingInSalonInfoFailure = (error) => ({
    type: GET_CHECKING_IN_SALON_INFO_FAILURE,
    payload: { error },
});

export const getPhoneAppointments = (business, phone, token, today) => {
    return (dispatch) => {
        dispatch(getPhoneAppointmentsStarted());
        API.get(
            `/api/checking-in/find-appointments?business=${business}&phone=${phone}&token=${token}&today=${today}`
        )
            .then((res) => {
                dispatch(getPhoneAppointmentsSuccess(res.data, phone));
            })
            .catch((err) => {
                catchError(err, dispatch, getPhoneAppointmentsFailure);
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 500);
            });
    };
};

const getPhoneAppointmentsStarted = () => ({
    type: GET_PHONE_APPOINTMENTS_STARTED,
});

const getPhoneAppointmentsSuccess = (data, phone) => ({
    type: GET_PHONE_APPOINTMENTS_SUCCESS,
    payload: { data },
    phone: phone,
});

const getPhoneAppointmentsFailure = (error) => ({
    type: GET_PHONE_APPOINTMENTS_FAILURE,
    payload: { error },
});

export const checkingInAppointments = (business, appointmentIds, token, businessClient) => {
    return (dispatch) => {
        dispatch(checkingInAppointmentsStarted());
        API.post(`/api/checking-in/appointments`, {
            business,
            appointmentIds,
            token,
            businessClient
        })
            .then((res) => {
                dispatch(checkingInAppointmentsSuccess());
            })
            .catch((err) => {
                catchError(err, dispatch, checkingInAppointmentsFailure);
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 500);
            });
    };
};

const checkingInAppointmentsStarted = () => ({
    type: CHECKING_IN_APPOINTMENTS_STARTED,
});

const checkingInAppointmentsSuccess = () => ({
    type: CHECKING_IN_APPOINTMENTS_SUCCESS,
});

const checkingInAppointmentsFailure = (error) => ({
    type: CHECKING_IN_APPOINTMENTS_FAILURE,
    payload: { error },
});

export const checkingInWalkInAppointment = (
    business,
    businessClient,
    appointment,
    token
) => {
    return (dispatch) => {
        dispatch(checkingInWalkInAppointmentStarted());
        API.post(`/api/checking-in/walk-in`, {
            business,
            businessClient,
            appointment,
            token,
        })
            .then((res) => {
                dispatch(checkingInWalkInAppointmentSuccess());
            })
            .catch((err) => {
                catchError(err, dispatch, checkingInWalkInAppointmentFailure);
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 500);
            });
    };
};

const checkingInWalkInAppointmentStarted = () => ({
    type: CHECKING_IN_WALK_IN_APPOINTMENT_STARTED,
});

const checkingInWalkInAppointmentSuccess = () => ({
    type: CHECKING_IN_WALK_IN_APPOINTMENT_SUCCESS,
});

const checkingInWalkInAppointmentFailure = (error) => ({
    type: CHECKING_IN_WALK_IN_APPOINTMENT_FAILURE,
    payload: { error },
});

// export const createBusinessClient = (businessClient, business) => {
//     return (dispatch) => {
//         dispatch(createBusinessClientStarted())
//         API.post(`/api/checking-in/create-client`, { businessClient, business }).then(res => {
//             dispatch(createBusinessClientSuccess(res.data));
//         }).catch(err => {
//             catchError(err, dispatch, createBusinessClientFailure)
//             setTimeout(function () {
//                 dispatch(removeErrorMessage());
//             }, 500);
//         })
//     }
// }

// const createBusinessClientStarted = () => ({
//     type: CREATE_BUSINESS_CLIENT_STARTED
// })

// const createBusinessClientSuccess = (data) => ({
//     type: CREATE_BUSINESS_CLIENT_SUCCESS,
//     payload: {
//         data
//     },
// });

// const createBusinessClientFailure = (error) => ({
//     type: CREATE_BUSINESS_CLIENT_FAILURE,
//     payload: { error },
// });

// export const updateBusinessClient = (business, businessClient) => {
//     return (dispatch) => {
//         dispatch(updateBusinessClientStarted())
//         API.patch(`/api/checking-in/update-client`, {business, businessClient}).then(res => {
//             dispatch(updateBusinessClientSuccess());
//         }).catch(err => {
//             catchError(err, dispatch, updateBusinessClientFailure)
//             setTimeout(function () {
//                 dispatch(removeErrorMessage());
//             }, 500);
//         })
//     }
// }

// const updateBusinessClientStarted = () => ({
//     type: UPDATE_BUSINESS_CLIENT_STARTED
// })

// const updateBusinessClientSuccess = () => ({
//     type: UPDATE_BUSINESS_CLIENT_SUCCESS,
// });

// const updateBusinessClientFailure = (error) => ({
//     type: UPDATE_BUSINESS_CLIENT_FAILURE,
//     payload: { error },
// });

const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
});

const redirectToNotFound = () => ({
    type: REDIRECT_TO_NOTFOUND,
});

const catchError = (error, dispatch, nextFunction) => {
    var message = error.message;
    if (error.response) {
        message = error.response.data.message;
        if (error.response.status === 400) {
            dispatch(redirectToNotFound());
        }
    }

    dispatch(nextFunction(message));
};
